
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.dropdown {
  height: 40px;
  padding: 0 11px;
  transition-delay: 0.2s;

  &:hover {
    > a {
      background: color.$white2;
    }
  }

  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: center;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
  }

  > span,
  > a {
    display: block;
    height: 40px;
    font-weight: typography.$light;
    font-size: 16px;
    line-height: 40px;
    padding: 0;
    text-decoration: none;

    @media screen and (min-width: 992px) {
      font-weight: typography.$medium;
      font-size: inherit;
      color: color.$black-color;
      padding: 0;
      position: relative;
      white-space: nowrap;
      width: 100%;
      text-align: center;
    }
  }
}

.nav-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 998;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: color.$black-color;
  background-clip: padding-box;
  border-radius: 0 0 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 991px) {
    display: none;
  }

  @media screen and (min-width: 992px) {
    width: 100%;
    left: 0;
    background-color: color.$white-color;
    top: 40px;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    padding: 20px 15px;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid color.$gray3;
    border-top-color: color.$light-gray;
    transition-delay: 0.2s;
  }
}

// Mobile css

.Menu-link-level-1 {
  border-bottom: 1px solid color.$light-gray;
  margin-bottom: 0;
  a {
    font-size: 14px;
    color: color.$black-color;
    line-height: 45px;
    display: block;
    font-weight: typography.$medium;
    position: relative;
    padding: 0 15px;
  }
}

.sprites-right-arrow-icon {
  background: variables.$background-lakeshore-sprites -96px -16px / 160px 160px;
  height: 16px;
  width: 12px;
  position: absolute;
  top: 12px;
  right: 18px;
}

.mp-back-title {
  display: flex;
  align-items: center;
  font-weight: typography.$bold;
  letter-spacing: -0.015em;
  font-size: 16px;
  line-height: 28px;
  padding: 11px 15px !important;
  border-bottom: 1px solid color.$light-gray;
  position: relative;
  background: color.$white-color;
  & + ul {
    height: 100%;
    overflow: auto;
    max-height: 100vh;
    padding-bottom: 260px;
  }
}

.sprites-left-arrow-icon {
  background: variables.$background-lakeshore-sprites -96px -16px / 160px 160px;
  margin-top: 1px;
  position: absolute;
  left: 15px;
  width: 12px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  height: 16px;
}

.title-text {
  margin-left: 20px;
}

.close-hamburger-menu {
  position: absolute;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 30px;
  z-index: 101;
}

.sprites-close-icon {
  width: 16px;
  height: 16px;
  background: variables.$background-lakeshore-sprites -112px -16px / 160px 160px;
}

.mp-level {
  position: absolute;
  top: 0px;
  background: color.$white-color;
  width: 100%;
  height: 100%;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.3s ease;
  z-index: 2220;
  overflow: hidden;
  left: 0;
}
