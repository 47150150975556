
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.searchFilter {
  width: 68.4%;
  position: relative;
  margin-left: 3.5%;
  margin-right: 3%;
  @media screen and (max-width: 991px) {
    width: calc(100% - 30px);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 11px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 767px) {
    bottom: 8px;
  }
  label.sr-only {
    display: none;
  }
  .inputSearch {
    border-radius: 20px;
    border: none;
    padding: 6px 65px 6px 18px;
    height: 33px;
    font-size: 14px;
    font-weight: typography.$light;
    letter-spacing: -0.015em;

    @media screen and (max-width: 991px) {
      font-size: 16px;
      &:focus {
        box-shadow: none;
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
    @media screen and (min-width: 768px) {
      height: 40px;
      margin-top: 0px;
    }
    &::-webkit-input-placeholder {
      color: color.$gray-silver;
    }
    &::-moz-placeholder {
      color: color.$gray-silver;
    }
    &:-ms-input-placeholder {
      color: color.$gray-silver;
    }
    &:-moz-placeholder {
      color: color.$gray-silver;
    }
  }
  .search-icon {
    width: 40px;
    min-width: auto;
    height: 37px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    z-index: 200;
    font-size: 27px;
    background: transparent;
    border: none;
    position: absolute;
    padding: 0;
    right: 1px;
    top: -1px;
    outline: 0;
    font-weight: typography.$light;
    &:hover {
      background: transparent;
      transform: scale(1);
    }
    @include breakpoint('medium') {
      top: 0px;
    }
    &::after {
      content: '\e95b';
      font-size: 27px;
      color: color.$gray4;
      position: relative;
      top: 0px;
      right: -2px;
      @include breakpoint('medium') {
        top: 2px;
        right: 1px;
      }
    }
  }

  .icn-x-close-lgt {
    width: 40px;
    min-width: auto;
    height: 40px;
    z-index: 200;
    font-size: 27px;
    border: none;
    position: absolute;
    padding: 0;
    right: 40px;
    top: 0;
    outline: 0;
    background: transparent;
    font-weight: typography.$light;
    &:hover {
      background: transparent;
      transform: scale(1);
    }
    &::after {
      content: '\e957';
      font-size: 12px;
      color: color.$gray4;
      display: inherit;
      margin-top: -2px;
    }
  }
  .searchWrapper {
    width: 100%;
    text-align: left;
    border: 1px solid color.$light-gray;
    border-top: 0;
    border-bottom: 3px solid color.$blue3;
    background: color.$white-color;
    padding: 20px 15px 10px;
    position: absolute;
    top: 95%;
    left: 0;
    z-index: 999;
    font-weight: typography.$medium;
    @include breakpoint('large') {
      padding: 20px 10px 10px;
    }
    .suggestionBorder {
      border-bottom: 1px solid color.$gray5;
      margin-bottom: 10px;
      padding-left: 0;
      @include breakpoint('large') {
        border-bottom: 0;
        margin-bottom: 10px;
        padding-left: calc(var(--bs-gutter-x) * 0.5);
      }
      h5 {
        font-size: 14px;
        color: color.$black-color;
        padding: 3px 0px;
        font-weight: typography.$bold;
        @include breakpoint('large') {
          padding: 3px 4px;
        }
      }
      a {
        color: color.$black-color;
        line-height: 18px;
        font-size: 14px;
        padding: 3px 0px;
        display: block;
        &:hover {
          background-color: color.$light-gray2;
        }
        @include breakpoint('large') {
          padding: 3px 5px;
        }
      }
      .tt-cursor {
        background-color: color.$light-gray2;
      }
      .suggestionItem {
        a {
          width: 100%;
          padding: 3px 0;
          @include breakpoint('large') {
            padding: 3px 5px;
          }
        }
      }
    }
    .recentItem {
      a {
        width: 100%;
      }
    }
    .product-suggestions {
      @include breakpoint('large') {
        border-left: 1px solid color.$light-gray;
      }
      h5 {
        font-size: 14px;
        color: color.$black-color;
        padding: 14px 0 3px;
        font-weight: typography.$bold;
        border-top: 1px solid color.$light-gray;
        margin: 5px 0 15px;
        @include breakpoint('medium') {
          border-top: 0;
          margin: 0 0 0.5rem;
          padding: 3px 0;
        }
        @include breakpoint('large') {
          padding: 3px 5px;
        }
      }
      .product-sugg-img {
        padding: 0 10px 8px 10px;
        max-height: 76px;
        max-width: 110px;
        margin-bottom: 7px;
      }
      .product-sugg-desc {
        padding: 10px;
        font-size: 13px;
        span {
          margin: 0 0 3px;
          color: color.$blue3;
          line-height: 18px;
          display: inline-block;
        }
        span.searchGsaUser {
          color: color.$green;
        }
        .grey {
          color: color.$gray2;
        }
        .price-small {
          font-size: 12px;
        }
      }
    }
    .recent-v-product-suggestions {
      padding: 0;
      @include breakpoint('large') {
        padding: 0 12px;
      }
      .product-sugg-img {
        max-height: 76px;
        max-width: 110px;
        margin-bottom: 7px;
      }
    }
    .product-suggestions-mobile {
      h5 {
        font-size: 14px;
        color: color.$black-color;
        padding: 3px 5px;
        font-weight: typography.$medium;
      }
      .product-sugg-img {
        padding: 0 10px 8px 10px;
      }
      .product-sugg-desc {
        padding: 10px;
        font-size: 13px;
        a {
          margin: 0 0 3px;
          color: color.$blue3;
          line-height: 18px;
          display: inline-block;
        }
        .grey {
          color: color.$gray2;
        }
        .price-small {
          font-size: 12px;
        }
      }
    }
    .recently-view-product {
      min-height: 85px;
      padding: 0px 5px;
      @include breakpoint('medium') {
        padding: 0 3px;
      }
      @include breakpoint('large') {
        padding: 0 10px;
      }
      .product-sugg-desc {
        span {
          color: color.$blue-black;
          font-family: typography.$bold;
          @include breakpoint('medium') {
            font-weight: 500;
          }
        }
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: -1px;
      background-color: color.$white-color;
      width: 12px;
      height: 20px;
      top: -20px;
      border-left: 1px solid color.$light-gray;
    }
    &::before {
      content: '';
      position: absolute;
      right: -1px;
      background-color: color.$white-color;
      width: 8px;
      height: 20px;
      top: -11px;
      border-right: 1px solid color.$light-gray;
      @include breakpoint('small') {
        top: -14px;
        width: 12px;
      }
    }
    .product-suggestions-wrp {
      @include breakpoint('medium') {
        padding-left: 0;
      }
      @include breakpoint('large') {
        padding-left: calc(var(--bs-gutter-x) * 0.5);
      }
      .product-suggestions-mobile {
        padding: 0 8px;
        @include breakpoint('medium') {
          padding: 0 0px 0 4px;
        }
        @include breakpoint('large') {
          padding: 0 0px 0 8px;
        }
      }
    }
  }
}

.inputSearch.error::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: color.$red;
}

.inputSearch.error::-moz-placeholder {
  /* Firefox 19+ */
  color: color.$red;
}

.inputSearch.error:-ms-input-placeholder {
  /* IE 10+ */
  color: color.$red;
}

.inputSearch.error:-moz-placeholder {
  /* Firefox 18- */
  color: color.$red;
}

.tt-cursor {
  background-color: color.$light-gray2;
}

.item-title {
  strong {
    font-weight: typography.$bold;
  }
}

.searchGsaUser {
  color: color.$green;
  display: 'block';
}

.suggestionItem,
.recentItem {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 8px;
  font-weight: typography.$light;
}

.searchArrow,
.closeArrow {
  padding: 0;
  min-width: auto;
  border-color: transparent;
  &:hover {
    transform: scale(1);
  }
}
.search-backdrop {
  display: none;
  @media screen and (max-width: 767px) {
    background: hwb(0 0% 100% / 0.4);
    left: 0;
    z-index: 9;
  }
}
.mobile-enhance-cross-icon {
  display: none;
}
.recent-search-wrapper {
  .suggestionBorder {
    padding: 0;
    margin-bottom: 10px;
    &.bottomBorder {
      border-bottom: 1px solid color.$gray5;
    }
    @include breakpoint('large') {
      padding: 0 12px;
      margin-bottom: 0;
      &.bottomBorder {
        border: none;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .search-backdrop,
  .recent-search-wrapper {
    display: none;
  }
  .search-mobile {
    .search-backdrop {
      display: block;
    }
    .searchFilter {
      position: static;
      padding: 0;
      width: 100%;
      .searchWrapper {
        height: calc(100vh - 100px);
        top: 100%;
        padding: 0;
        overflow-y: auto;
        padding: 0 0 150px;
        &::before,
        &::after {
          content: none;
        }
        .product-suggestions {
          h5 {
            border: none;
          }
        }
        .suggestionBorder {
          padding: 0;
          border: none;
          margin: 0 0 10px;
          .suggestionItem {
            padding: 10px 15px;
            border-bottom: 1px solid color.$gray5;
          }
        }
        .recent-v-product-suggestions {
          margin-top: 20px;
          padding: 0 12px;
        }
      }
      .mobile-enhance-cross-icon {
        display: block;
      }
      .icn-x-close-lgt {
        right: 54px;
        top: 12px;
        height: 30px;
      }
      .inputWrap {
        background-color: #e8e8e8;
        padding: 8.5px 20px;
      }
      .search-icon {
        right: 25px;
        top: 9px;
      }
      .recent-search-wrapper {
        position: relative;
        height: 100vh;
        padding: 0 0 250px;
        display: block;
        &::before {
          content: none;
        }
        h5 {
          display: none;
        }
        .suggestionBorder {
          padding: 0;
        }
        .recentItem {
          padding: 10px 15px;
          border-bottom: 1px solid color.$gray5;
        }
      }
    }
  }
}
