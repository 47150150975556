
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.action-btn-main {
  position: absolute;
  bottom: 3%;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  .action-btn {
    border-radius: 33px;
    width: auto;
    font-size: 12px;
    line-height: 16px;
    padding: 5px 20px;
    font-weight: typography.$medium;
    transition: all 0.1s ease-in-out;
    @media screen and (min-width: 1200px) {
      padding: 7px 26px;
      font-size: 16px;
      line-height: 20px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      transform: scale(1.04, 1.04);
    }
  }
  .link-btn {
    color: color.$white-color;
    border: 2px solid color.$white-color;
    display: inline-block;
  }
}
