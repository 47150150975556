
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.footer-wrapper {
  padding: 0 0 10px;
  font-size: 16px;
  border-top: 4px solid color.$red3;
  margin-top: 20px;
  line-height: 1.2;
  background: color.$gray3;
  @media screen and (min-width: 768px) {
    padding: 30px 0 0;
    font-size: 12px;
    .footer-links {
      width: 60%;
      padding: 0 0 0 15px;
    }
    .footer-contact {
      width: 40%;
      border-left: 1px solid color.$light-gray;
    }
  }
  @media screen and (min-width: 992px) {
    padding-bottom: 0px;
    font-size: 13px;
  }
  .footer-contact {
    @media screen and (min-width: 992px) {
      padding-left: 31px;
    }
  }
  .container {
    padding: 0 13px;
    margin: 0 auto;
    @media (min-width: 992px) {
      width: 970px;
      padding: 0 15px;
    }
    @media (min-width: 1200px) {
      width: 1170px;
    }
  }
}

// Get the app CSS
.footer-get-the-app {
  flex: 0 0 37.2%;
  @media all and (max-width: 767px) {
    flex: 0 0 100%;
  }
  h3 {
    font-weight: typography.$bold;
    letter-spacing: -0.015em;
    font-size: 22px;
    padding: 0 0 2px;
    color: color.$black-color;
    margin-bottom: 8px;
    line-height: 22px;
  }
}

.get-app-content {
  display: flex;
  gap: 14px;
  @media all and (max-width: 767px) {
    display: block;
  }
}

.qr-code-text {
  font-size: 13px;
  line-height: 16px;
  color: color.$black-color;
  font-weight: typography.$medium;
  margin-bottom: 14px;
  letter-spacing: -0.05em;
  margin-top: 1px;
  @media all and (max-width: 767px) {
    display: none;
  }
}

.footer-get-the-app-wrap {
  display: flex;
  justify-content: flex-end;
  @media all and (max-width: 767px) {
    justify-content: flex-start;
    border-top: 1px solid color.$light-gray;
    margin-top: 15px;
    padding-top: 15px;
  }
}

.app-store-badge-wrap {
  @media all and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
  a {
    img {
      @media all and (max-width: 767px) {
        width: 100px;
        height: 35px;
      }
    }
  }
}

.qr-code {
  position: relative;
  left: 1px;
  @media all and (max-width: 767px) {
    display: none;
  }
}

.footer-badge {
  text-align: center;
  margin-bottom: 20px;
  > div {
    display: block;
  }
  @include breakpoint('medium') {
    > div {
      display: none;
    }
  }
}
