
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.plp-product-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include breakpoint('large') {
    width: 70%;
  }
  h1 {
    font-size: 25px;
    font-weight: typography.$bold;
  }
}
