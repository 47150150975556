
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.online {
  position: relative;
  &::after {
    background: variables.$background-lakeshore-sprites 80px -80px / 200px 200px;
    content: '';
    margin-left: 8px;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
  }
}

.talkativeOnline {
  color: color.$primary-color;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    padding-bottom: 10px;
  }
}

.talkativeOffline {
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    padding-bottom: 10px;
  }
}
