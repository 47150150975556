
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.nav-main {
  display: flex;
  justify-content: space-between;
  list-style: none;
  width: 100%;
}

.list-item-title {
  display: flex;
  align-items: center;
  font-weight: typography.$bold;
  letter-spacing: -0.015em;
  font-size: 16px;
  line-height: 28px;
  padding: 11px 15px;
  border-bottom: 1px solid #ccc;
  justify-content: center;
  position: relative;
}

.header-logo {
  width: 130px;
  height: 32.5px;
  background: url(/assets/images/LakeshoreLogoSprites_v01.svg) 0 0 / 132px 460px;
  display: block;
}

.close-hamburger-menu {
  position: absolute;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 30px;
  z-index: 101;
}

.sprites-close-icon {
  width: 16px;
  height: 16px;
  background: variables.$background-lakeshore-sprites -112px -16px / 160px 160px;
}

.account-mobile-menu-right-wrapper {
  ul li {
    padding: 8px 15px;
  }
}
