
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.new-nav {
  border: none;
  z-index: 1045;
  background: color.$white-color;
  transform: translate3d(-100%, 0, 0);
  position: fixed;
  height: 100%;
  transition: all 0.5s;
  top: 0;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 375px;
  }

  li {
    width: 100%;
    padding: 0 15px;
    list-style: none;
    height: 40px;
    border-bottom: none;
    display: flex;
    align-items: center;

    > a {
      height: 40px;
      font-weight: typography.$light;
      text-decoration: none;
      padding: 0;
      font-size: 14px;
      line-height: 45px;
      font-weight: 500;
      display: flex;
      align-items: center;
      position: relative;
      letter-spacing: -0.015em;
      color: color.$black-color;
    }

    &::before {
      content: '';
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }

    &:nth-child(2)::before {
      background: variables.$background-lakeshore-sprites 0 -20px / 200px 200px;
    }

    &:nth-child(3)::before {
      background: variables.$background-lakeshore-sprites -20px -20px / 200px
        200px;
    }

    &:nth-child(4)::before {
      background: variables.$background-lakeshore-sprites -40px -20px / 200px
        200px;
    }

    &:nth-child(5)::before {
      background: variables.$background-lakeshore-sprites -60px -20px / 200px
        200px;
    }

    &:nth-child(6)::before {
      background: variables.$background-lakeshore-sprites -80px -20px / 200px
        200px;
    }

    &:nth-child(7)::before {
      background: variables.$background-lakeshore-sprites -100px -20px / 200px
        200px;
    }
  }

  li:first-child {
    display: none;
  }
}

.navMenu {
  position: relative;
  box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.3);
  z-index: 6;
  background: color.$gray3;
}

.mp-menu {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  @media screen and (min-width: 768px) {
    transform: translate3d(-375px, 0, 0);
    width: 375px;
  }
}
