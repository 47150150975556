
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.section-image-row {
  margin-bottom: 0;
  h2:empty {
    display: none;
  }
  @include breakpoint('medium') {
    margin-bottom: 30px;
  }
  & > div {
    margin: 0;
  }
  & > div > div:nth-of-type(1) {
    padding-left: 0;
  }
  & > div > *:last-child {
    padding-right: 0;
  }
  @media screen and (max-width: 767px) {
    & > div > * {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.image-row-banner-wrap {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: space-between;
  @include breakpoint('medium') {
    gap: 0px;
  }
}
