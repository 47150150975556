
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.subscribe-form {
  @media screen and (max-width: 767px) {
    padding: 0 15px !important;
  }
  .subscribeHeading {
    padding: 15px 0;
    color: color.$black-color;
    font-weight: typography.$bold;
    line-height: 22px;
    font-size: 22px;
    margin-bottom: 0;
    @media screen and (min-width: 768px) {
      font-weight: typography.$bold;
      letter-spacing: -0.015em;
      font-size: 22px;
      padding: 0 0 6px;
      color: color.$black-color;
      margin-bottom: 10px;
      line-height: 22px;
    }
  }
  .sign-up-h {
    padding-bottom: 10px;
    margin: 0 0 6px;
    letter-spacing: -0.015em;
    p {
      color: color.$black-color;
    }
  }
  .footer-email {
    display: flex;
    margin: 0 0 12px;
    .subscribe-input {
      width: 75%;
      margin: 0 10px 0 0;
      input {
        height: 60px;
        border-radius: 4px;
        font-size: 14px;
        color: color.$gray;
        &::-webkit-input-placeholder {
          color: color.$gray-silver;
        }
        &::-moz-placeholder {
          color: color.$gray-silver;
        }
        &:-ms-input-placeholder {
          color: color.$gray-silver;
        }
        &:-moz-placeholder {
          color: color.$gray-silver;
        }
        @media screen and (min-width: 768px) {
          height: 34px;
          margin: 0 10px 0 0;
          padding: 6px 12px;
          font-size: 14px;
          font-weight: typography.$light;
          letter-spacing: -0.015em;
        }
      }
    }
    .subscribe-cta {
      width: calc(25% - 15px);
      height: 60px;
      @media screen and (min-width: 768px) {
        height: auto;
      }
      .joinBtn {
        width: 100%;
        height: 100%;
        font-weight: typography.$medium;
        font-size: 16px;
        padding: 10px;
        min-width: auto;
        @media screen and (min-width: 768px) {
          padding: 5px;
          font-size: 14px;
          line-height: 20px;
          min-width: 50px;
        }
      }
    }
  }
  .error-message {
    margin: -10px 0 10px;
    display: block;
  }
}

//  Bottom Text CSS
.text-terms {
  margin-bottom: 15px;
  padding-top: 5px;
  p {
    margin-bottom: 17px;
    span {
      font-size: 0.813rem;
      color: color.$black-color;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
      a {
        text-decoration: underline;
        color: color.$black-color;
        &.blue-link {
          text-decoration: none;
          color: color.$primary-color;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
