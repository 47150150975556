
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.dropdown-menu {
  text-align: left;
  @media screen and (min-width: 992px) {
    margin-bottom: 27px;
  }
  .dropdown-header {
    letter-spacing: -0.015em;
    font-size: 17px;
    font-weight: 700;
    padding: 6px 0;
    color: color.$black;
    @media screen and (min-width: 992px) {
      font-weight: typography.$bold;
      font-size: 15px;
      line-height: 22px;
      color: color.$black4;
      padding: 0;
      margin-bottom: 8px;
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      padding: 2px 0;
      margin-bottom: 5px;
      @media screen and (min-width: 992px) {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 0 8px;
        font-weight: typography.$light;
        font-size: 12px;
      }

      a {
        color: color.$black-color;
        display: block;
        &:hover {
          text-decoration: underline;
        }
        @media screen and (min-width: 992px) {
          line-height: 16px;
        }
      }
    }
  }
}

//  Mobile css

.sprites-right-arrow-icon {
  background: variables.$background-lakeshore-sprites -96px -16px / 160px 160px;
  position: absolute;
  right: 20px;
  top: 12px;
  width: 12px;
  height: 16px;
}
.light {
  font-weight: typography.$light;
}
.mp-back-title {
  display: flex;
  align-items: center;
  font-weight: typography.$bold;
  letter-spacing: -0.015em;
  font-size: 16px;
  line-height: 28px;
  padding: 11px 15px !important;
  border-bottom: 1px solid color.$light-gray;
  position: relative;
}

.sprites-left-arrow-icon {
  width: 12px;
  height: 16px;
  background: variables.$background-lakeshore-sprites -96px -16px / 160px 160px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.title-text {
  margin-left: 15px;
}

.close-hamburger-menu {
  position: absolute;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 30px;
  z-index: 101;
}

.sprites-close-icon {
  width: 16px;
  height: 16px;
  background: variables.$background-lakeshore-sprites -112px -16px / 160px 160px;
}

.mp-level {
  position: absolute;
  top: 0px;
  background: color.$white-color;
  width: 100%;
  height: 100%;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.3s ease;
  z-index: 2220;
  overflow: hidden;
  left: 0;
}

.sub-menu-level-two-wrap2 {
  padding-bottom: 100px;
  li {
    border-bottom: 1px solid color.$light-gray;
    margin-bottom: 0;
  }
  a {
    padding: 0 15px;
  }
  @include breakpoint('large') {
    display: none;
  }
}

.sub-menu-level-two-link-wrap {
  border-bottom: 1px solid color.$light-gray;
  margin-bottom: 0;
  a {
    padding: 0 15px;
  }
  @include breakpoint('large') {
    display: none;
  }
}

.sub-menu-third-level2 {
  position: fixed;
  top: 0px;
  background: color.$white-color;
  width: 100%;
  height: 100vh;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.3s ease;
  z-index: 2220;
  overflow: hidden;
  left: 0;
  .list-item {
    padding: 0;
    position: relative;
    border-bottom: 1px solid color.$light-gray;
    margin-bottom: 0;
  }
}

.level-3-ul {
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  padding-bottom: 190px;
}
