
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.view-text-wrap {
  max-width: 1500px;
  margin: 0 auto 40px;
  width: 100%;
  text-align: center;
  padding: 0 3px;
  @include breakpoint('large') {
    margin-top: 20px;
  }
}

.view-text-button {
  margin: 15px 0 0;
  font-weight: typography.$medium;
}

.text-content {
  width: 100%;
  text-align: center;
  font-weight: typography.$light;
}

.hidden-text {
  margin-top: 1rem;
}

@media (orientation: landscape) {
  .hidden-text {
    margin-top: 1.5rem;
  }
}

.view-text-button::after {
  content: 'View More';
}

.view-text-button[aria-expanded='true']::after {
  content: 'View Less';
}
