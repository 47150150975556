
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.spacer-15 {
  padding-bottom: 15px;
}

.spacer-30 {
  padding-bottom: 30px;
}

.spacer-45,
.spacer-60 {
  padding-bottom: 45px;
}

@media screen and (min-width: 768px) {
  .spacer-60 {
    padding-bottom: 60px;
  }
}
