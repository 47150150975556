
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.footer-social {
  margin: 0 0 20px;
}

.social-icons-container {
  @include breakpoint('medium') {
    min-height: 70px;
  }
}
.text-terms {
  margin: 20px 12px 25px;
  p {
    font-size: 22px;
    color: color.$gray2;
    strong {
      font-weight: typography.$bold;
    }
  }
}

.footer-social {
  margin: 0px 0 20px;
  display: flex;
  justify-content: center;
  gap: 22px;
  @media screen and (max-width: 991px) {
    gap: 0;
    a {
      margin: 0 9px;
    }
  }
  a {
    img {
      @media screen and (max-width: 991px) {
        width: 38px;
        height: 38px;
      }
    }
  }
}

@media all and (max-width: 991px) {
  .text-terms {
    margin: 20px auto;
    text-align: center;
    padding: 0 14px 0 30px;
    p {
      text-align: center;
      font-size: 14px;
      color: color.$gray2;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 19px;
    }
  }
}
