
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.menu-link {
  color: color.$black;
  display: block;
  font-size: 12px;
  font-weight: typography.$light;
  padding: 0 30px;
  border-bottom: 1px solid color.$light-gray;
  @include breakpoint('large') {
    border-bottom: none;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }
}
