
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.productSection {
  margin: 0 auto 30px;

  .productHeading {
    margin: 20px 40px 40px;
  }

  .slider-items {
    margin-top: -6px;
    padding: 0 4px;
    .item {
      display: flex;
      min-height: 250px;
      margin-bottom: 15px;
      flex-wrap: wrap;
      .cardItem {
        display: flex;
        flex-direction: column;
        width: 20%;
        overflow: hidden;
        display: flex;
        justify-content: space-around;
        padding: 0 15px 15px;
        align-items: flex-start;
        .product-content {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          gap: 0;
        }
        .product-img {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          img {
            max-width: 100%;
          }
        }
        .product-name {
          color: color.$black-color;
          font-weight: typography.$bold;
          letter-spacing: -0.015em;
          font-size: 14px;
          max-width: 180px;
          margin-bottom: 2px;
          a {
            color: inherit;
          }
        }
        .price {
          font-size: 14px;
          line-height: 18px;
          color: color.$black-color;
          max-width: 180px;
          del {
            text-decoration: none;
            color: color.$black-color;
            font-size: 0.86em;
            line-height: 18px;
            display: block;
          }
        }
        .red {
          color: color.$red;
        }
        .black {
          color: color.$black;
        }
        .green {
          color: color.$green2;
        }
        .new-item-label {
          margin-top: 10px;
        }
        .new-icon {
          background-color: color.$tangerine2;
          display: inline-block;
          margin-right: 3px;
          padding: 3px 6px;
          text-transform: uppercase;
          vertical-align: text-bottom;
          font-size: 11px;
          color: color.$white-color;
          font-weight: typography.$bold;
          border-radius: 4px;
          line-height: normal;
        }
        .blue {
          color: color.$primary-color;
          font-size: 12px;
        }
        .rating-slider {
          padding: 0;
          .stars {
            width: 100px;
            height: 18px;
            background: variables.$background-lakeshore-sprites -400px -442px /
              1000px 1000px;
            position: relative;
            max-width: 180px;
            display: inline-block;
          }
          .gold {
            color: color.$yellow2;
            display: inline-block;
            height: 18px;
            background: variables.$background-lakeshore-sprites -300px -442px /
              1000px 1000px;
            position: absolute;
          }
          .pdp_reviewcount {
            font-size: 12px;
            position: relative;
            bottom: 4px;
            a {
              cursor: pointer;
              color: color.$primary-color;
              outline: 0;
              text-decoration: none;
            }
          }
        }
        .spacial-label {
          max-width: 180px;
          margin: 0 auto;
        }
        .fs11 {
          font-size: 11px;
        }
      }
    }
    .show-on-mobile.item {
      list-style: none;
      margin: 0 auto;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0;
      white-space: nowrap;
      flex-wrap: nowrap;
      .cardItem {
        width: 17vw;
        padding: 0;
        padding-bottom: 20px;
        margin-right: 20px;
        white-space: normal;
        flex-shrink: 0;
        @media screen and (max-width: 576px) {
          width: 31vw;
        }
        .product-name {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .prev {
    width: 40px;
    opacity: 1;
    span {
      background: variables.$background-lakeshore-sprites -649px -39px / 400px
        400px;
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      width: 21px;
      height: 40px;
      position: absolute;
      top: 75px;
    }
  }

  .prev_disabled {
    width: 40px;
    opacity: 0.3;
    &:hover {
      opacity: 0.3;
    }
    span {
      opacity: 0.3;
      background: variables.$background-lakeshore-sprites -649px -39px / 400px
        400px;
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      width: 21px;
      height: 40px;
      position: absolute;
      top: 75px;
    }
  }

  .next {
    width: 40px;
    opacity: 1;
    span {
      background: variables.$background-lakeshore-sprites -649px -39px / 400px
        400px;
      width: 21px;
      height: 40px;
      position: absolute;
      top: 75px;
    }
  }

  .next_disabled {
    width: 40px;
    opacity: 0.3;
    &:hover {
      opacity: 0.3;
    }
    span {
      opacity: 0.3;
      background: variables.$background-lakeshore-sprites -649px -39px / 400px
        400px;
      width: 21px;
      height: 40px;
      position: absolute;
      top: 75px;
    }
  }
}
