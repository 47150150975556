
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.blue {
  background: color.$green-blue2;
}

.micro-banner-bg-color {
  background: var(--microBannerColor);
}

.mircoBannerMain {
  position: relative;
  margin-bottom: 30px;
  min-height: 180px;
  @media only screen and (min-width: 575px) {
    min-height: 115px;
  }
}

.mircoBanner-bg-Main {
  margin: 0px 0 30px;
  @media only screen and (max-width: 767px) {
    min-height: auto;
  }
}

.microPromoText {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.055em;
  padding: 0 20px 0 30px;
  width: auto;
  color: color.$white-color;
  font-weight: typography.$bold;
  margin-bottom: 15px;
  @media only screen and (min-width: 575px) {
    font-size: 24px;
    line-height: 28px;
    width: 50%;
    margin-bottom: 0;
  }
  @media screen and (min-width: 1200px) {
    font-size: 38px;
    line-height: 48px;
    width: auto;
  }
}

.buttonText {
  .microBtn {
    color: color.$white-color;
    display: inline-block;
    background-color: var(--buttonColor);
    border-color: var(--buttonColor);
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    &:hover {
      background-color: color.$danger-btn-hover-color;
      border-color: color.$danger-btn-hover-color;
      transform: scale(1.04, 1.04);
      transform: scale(1.04);
      -ms-transform: scale(1.04);
      -moz-transform: scale(1.04);
      -webkit-transform: scale(1.04);
    }
  }
}

.mircoLink {
  color: color.$white-color;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.055em;
  text-decoration: underline;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
  @include breakpoint('small') {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.055em;
    cursor: pointer;
  }
  @include breakpoint('large') {
    font-size: 11px;
  }
}

.image-btn-main {
  position: absolute;
  right: 2%;
  @media screen and (min-width: 1200px) {
    right: 5%;
  }
  @media only screen and (max-width: 767px) {
    right: 0;
    bottom: 7%;
    left: 0;
    text-align: center;
  }
}

.seeDetails {
  cursor: pointer;
}

.imaga-wrapper {
  margin: 0 -1rem;
}

.image-text-button {
  margin-bottom: 15px;
}
