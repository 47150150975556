
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.menu-img-grp {
  margin: 0 15px;
  a {
    width: 50%;
    display: inline-block !important;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.menu-img-wrp {
  margin-bottom: 20px;
}

.title-decor {
  padding: 15px 0 0 15px;
  font-size: 15px;
  color: color.$black-color;
  line-height: 45px;
  @include breakpoint('large') {
    padding-top: 0;
    line-height: normal;
    font-weight: typography.$bold;
  }
}

//CSS for Desktop Menu Item

.dropdown-header {
  letter-spacing: -0.015em;
  font-size: 17px;
  font-weight: 700;
  padding: 6px 0;
  color: #000;
  display: none;
  @media screen and (min-width: 992px) {
    font-size: 15px;
    line-height: 22px;
    color: #252525;
    padding: 0;
    margin-bottom: 8px;
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
}
