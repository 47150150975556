
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.header-wrapper {
  position: relative;
  background: color.$blue;
  height: 100px;
  padding-top: 0px;
  padding-bottom: 8px;
  @media screen and (min-width: 768px) {
    height: 108px;
    padding-top: 6px;
    padding-bottom: 11px;
  }
  @media screen and (min-width: 992px) {
    padding-bottom: 8px;
    height: 115px;
  }
  .header-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 992px) {
      display: block;
    }
  }
  .header-center {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 25px;
    padding: 0px;
    width: auto;
    @media screen and (min-width: 376px) {
      width: 50%;
    }
  }
  .header-right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    min-height: 25px;
    padding-right: 8px;
    @media screen and (min-width: 768px) {
      justify-content: flex-end;
      padding-left: 0px;
      padding-right: 6px;
    }
    @media screen and (min-width: 992px) {
      padding-right: 12px;
    }
  }
  &:last-child {
    display: none;
  }
  .header-your-store-section {
    display: none;
    @include breakpoint('large') {
      display: block;
    }
  }
}
