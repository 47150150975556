
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.ll-logo {
  width: 148px;
  height: 37px;
  display: inline-block;
  margin-top: 0px;
  @media screen and (min-width: 768px) {
    width: 147px;
    position: relative;
    top: 0px;
    margin-top: 0px;
  }
  @media screen and (min-width: 992px) {
    width: 188px;
    margin-top: -3px;
  }
  @media screen and (min-width: 1200px) {
    width: 242px;
    margin-top: -11px;
  }
  .ll-logo-red {
    width: 148px;
    height: 37px;
    background: url(/assets/images/LakeshoreLogoSprites_v01.svg) 0 0 / 152px
      532px;
    display: inline-block;
    @media screen and (min-width: 768px) {
      width: 147px;
      height: 37px;
      background: url(/assets/images/LakeshoreLogoSprites_v01.svg) 0 0 / 150px
        520px;
    }
    @media screen and (min-width: 992px) {
      width: 188px;
      height: 47px;
      background: url(/assets/images/LakeshoreLogoSprites_v01.svg) 0 0 / 192px
        672px;
    }
    @media screen and (min-width: 1200px) {
      width: 242px;
      height: 62px;
      background: url(/assets/images/LakeshoreLogoSprites_v01.svg) 0 0 / 248px
        868px;
    }
  }
}
