
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.menu-link-img {
  flex: 1;
  color: color.$black-color;
  display: block;
  img {
    height: auto;
    width: 100%;
    margin-top: 15px;
    @include breakpoint('large') {
      margin-top: 0;
    }
  }
  &:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 992px) {
    text-align: left;
    font-weight: typography.$light;
    line-height: 17px;

    span {
      margin-top: 7px;
      display: block;
      text-align: center;
    }
  }
}

.menu-link-img.menu-link-furniture-img {
  img.image {
    max-width: 100%;
  }
}

.imageDecorations {
  img {
    border: 1px solid color.$light-gray;
    border-radius: 15px;
    // width: 100%;
  }
}

.menu-link {
  list-style: none;
  padding: 2px 0;
  margin-bottom: 5px;
  @media screen and (min-width: 992px) {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 0 8px;
    font-weight: typography.$light;
    font-size: 12px;
  }

  a {
    color: #020202;
    display: block;
    &:hover {
      text-decoration: underline;
    }
    @media screen and (min-width: 992px) {
      line-height: 16px;
    }
  }
}

.menu-link-mobile {
  padding: 0px;
  border-bottom: 1px solid #ccc;
}
