
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.page-title-container {
  width: 100%;
  padding: 0 15px;
  color: #181818;

  .page-title {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .title-copy {
      font-weight: typography.$bold;
      font-size: 25px;
      letter-spacing: -0.42px;
      line-height: 28px;
      display: flex;
      align-items: center;
      margin-bottom: 0px !important;
    }

    .right-copy {
      display: none;
    }

    @media screen and (min-width: 992px) {
      justify-content: space-between;

      .title-copy {
        font-size: 28px;
        line-height: 30px;
      }
      .right-copy {
        display: flex;
        align-items: center;

        .bullet {
          font-size: 17px;
          margin: 0px 10px;
        }
      }

      &.gray-rule {
        padding-bottom: 15px;
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }
}
